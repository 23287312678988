import * as React from "react";
import "@aws-amplify/ui/dist/style.css";
import { Router, useLocation } from "@reach/router";
import Amplify, { I18n } from "aws-amplify";
import awsconfig from "aws-exports";
import { withAuthenticator } from "aws-amplify-react";
import { Helmet } from "react-helmet";
import Authors from "components/pages/cms/Authors";
import Author from "components/pages/cms/Author";

import Layout from "components/layouts/CmsLayout";
import FormDialog from "components/molecures/dialogs/FormDialog";
import CmsApp from "apps/cms";

function App() {
  const location = useLocation();
  let background;

  React.useEffect(() => {
    Amplify.configure({
      ...awsconfig,
      aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    });
  }, []);

  if (location.state && location.state.background) {
    background = location.state.background;
  }

  return (
    <CmsApp>
      <div className="App">
        <Helmet>
          <title>Kazi Online</title>
        </Helmet>

        <Layout>
          <Router basepath="/cms/admin" location={background || location}>
            <Authors path="/authors" />
          </Router>
        </Layout>

        {background && (
          <FormDialog>
            <Router basepath="/cms/admin" location={location}>
              <Author path="/authors/:id/:action" />
            </Router>
          </FormDialog>
        )}
      </div>
    </CmsApp>
  );
}

// @todo 仮：日本語
const dict = {
  ja: {
    "User does not exist.": "ユーザー名またはパスワードが正しくありません",
    "Incorrect username or password.":
      "ユーザー名またはパスワードが正しくありません",
    "Password did not conform with policy: Password not long enough":
      "パスワードが短すぎます",
    "Invalid session for the user, session is expired.": "セッションが無効です",
    "Password attempts exceeded":
      "パスワードを一定回数以上間違えたため、アカウントを無効にしました",
    "Account recovery requires verified contact information":
      "アカウントを復旧するには連絡先の確認が必要です",
    "Back to Sign In": "サインイン画面へ戻る",
    "Change Password": "パスワード変更",
    Change: "変更",
    Code: "確認コード",
    "Confirm a Code": "コードを確認する",
    "Confirm Sign In": "確認",
    "Confirm Sign Up": "サインアップ",
    Confirm: "確認",
    Email: "メールアドレス",
    "Forgot Password": "パスワードをお忘れの場合",
    "Loading...": "ロード中...",
    "New Password": "新しいパスワード",
    "Reset password": "パスワードリセット",
    "No MFA": "MFAなし",
    Password: "パスワード",
    "Phone Number": "電話番号",
    "Pick a File": "ファイルを選択する",
    "Resend a Code": "確認コードを再送する",
    "Resend Code": "確認コードを再送する",
    "Select MFA Type": "MFAタイプの選択",
    "Select your preferred MFA Type": "MFAタイプを選択してください",
    "Sign In Account": "サインイン",
    "Sign In": "サインイン",
    "Sign in": "サインイン",
    "Sign Out": "サインアウト",
    "Sign Up Account": "サインアップ",
    "Sign Up": "サインアップ",
    "Create account": "サインアップ",
    Skip: "スキップする",
    Submit: "保存",
    Username: "ユーザー名",
    "Verify Contact": "確認",
    Verify: "確認する",
    "Create a new account": "サインアップ",
    "Sign in to your account": "サインイン",
    "No account? ": "アカウントがない",
    "Forget your password? ": "パスワードを忘れた",
    "Have an account? ": "アカウントがある",
    "Create Account": "アカウント作成",
  },
};
I18n.putVocabularies(dict);
I18n.setLanguage("ja");
// Hub.listen("auth", ({ payload: { event, data } }) => {
//   switch (event) {
//     case "configured":
//     case "signUp":
//     case "signOut":
//     case "signIn_failure":
//     case "signIn":
//       initUser();
//       break;
//   }
// });

// @ts-ignore
export default withAuthenticator(App, {
  signUpConfig: {
    hiddenDefaults: ["phone_number"],
  },
});
