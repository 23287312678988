import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

type Props = {
  title?: string;
  children?: React.ReactNode;
};

export default function FormDialog(props: Props) {
  const { children } = props;
  const handleClose = () => {
    window.history.back();
  };

  return (
    <div>
      <Dialog
        open
        fullWidth
        scroll="paper"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            戻る
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
